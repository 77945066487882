<template>
  <div class="lists-table">
    <v-row no-gutters class="row-header">
      <v-col cols="3" sm="3">
        <strong>Nome lista</strong>
      </v-col>
      <v-col cols="5" sm="5">
        <strong>N. prodotti</strong>
      </v-col>
      <v-col cols="4" sm="4"> </v-col>
    </v-row>

    <v-list>
      <v-list-item
        v-for="(list, index) in lists"
        :key="list.id"
        v-bind:to="'/profile/lists/' + list.id"
        :class="{ zebra: index % 2 == 0 }"
      >
        <v-row
          no-gutters
          align="center"
          :class="{ default: list.isDefault }"
          v-if="!list.isEdit"
        >
          <v-col cols="3" sm="3">
            <strong class="name">{{ list.name }}</strong>
          </v-col>
          <v-col cols="5" sm="5">
            <span>{{ list.size }} prodotti</span>
          </v-col>
          <v-col cols="4" sm="4" class="d-flex justify-end align-center">
            <v-tooltip left v-if="!list.isDefault">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  large
                  icon
                  v-on:click.prevent="setDefault(list.id)"
                  class="secondary-icon-button mr-1"
                >
                  <v-icon>mdi-star-outline</v-icon>
                </v-btn>
              </template>
              <span> Imposta come lista preferita</span>
            </v-tooltip>
            <v-tooltip left v-if="list.isDefault">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  large
                  icon
                  v-on:click.prevent="setDefault(list.id)"
                  class="main-button mr-1"
                >
                  <v-icon class="default-icon">mdi-star</v-icon>
                </v-btn>
              </template>
              <span> Imposta come lista preferita</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  large
                  v-bind="attrs"
                  v-on="on"
                  icon
                  v-on:click.prevent="addAllToCart(list)"
                  class="main-button mr-1"
                >
                  <v-icon>$cart</v-icon>
                </v-btn>
              </template>
              <span> Aggiungi tutto al carrello</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  large
                  icon
                  v-on:click.prevent="setEdit(list)"
                  class="main-button mr-1"
                >
                  <v-icon>$edit</v-icon>
                </v-btn>
              </template>
              <span>Rinomina lista</span>
            </v-tooltip>

            <v-tooltip left v-if="!list.isDefault">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  large
                  v-bind="attrs"
                  v-on="on"
                  icon
                  v-on:click.prevent="removeList(list.id, list.name)"
                  class="secondary-icon-button"
                >
                  <v-icon>$delete</v-icon>
                </v-btn>
              </template>
              <span>Elimina lista</span>
            </v-tooltip>
          </v-col>
        </v-row>

        <v-row no-gutters align="center" v-if="list.isEdit">
          <!-- label="Inserisci il nome" -->
          <v-col cols="6" sm="6">
            <v-text-field
              hide-details="auto"
              v-model="list.tempName"
              v-on:click.prevent="() => {}"
            ></v-text-field>
          </v-col>
          <v-col cols="2" sm="2"> </v-col>
          <v-col cols="4" sm="4">
            <v-btn large icon v-on:click.prevent="confirmEdit(list)">
              <v-icon>mdi-check</v-icon>
            </v-btn>
            <v-btn large icon v-on:click.prevent="undoEdit(list)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions } from "vuex";
import login from "~/mixins/login";

export default {
  name: "ListsTable",
  mixins: [login],
  props: ["lists"],
  methods: {
    ...mapActions({
      addProductsFromWishlist: "cart/addProductsFromWishlist"
    }),
    setDefault(listId) {
      this.$emit("setdefault", listId);
    },
    removeList(listId, listName) {
      this.$emit("remove", { listId, listName });
    },
    setEdit(list) {
      Vue.set(list, "isEdit", true);
      Vue.set(list, "tempName", list.name);
      //   list.isEdit = true;
      //   list.tempName = list.name;
      //   this.$emit("remove", listId);
    },
    undoEdit(list) {
      list.isEdit = false;
      list.tempName = null;
    },
    confirmEdit(list) {
      this.$emit("rename", list);
    },
    async addAllToCart(list) {
      if (await this.needLogin("addAllToCart")) {
        if (await this.needAddress()) {
          if (await this.needTimeslot()) {
            let res = await global.vm.$dialog.confirm({
              text: global.vm.$t("message.addAllToList")
            });
            if (res) {
              await this.addProductsFromWishlist(list.id);
            }
          }
        }
      }
    }
  }
};
</script>
