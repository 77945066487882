var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lists-table"},[_c('v-row',{staticClass:"row-header",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"3","sm":"3"}},[_c('strong',[_vm._v("Nome lista")])]),_c('v-col',{attrs:{"cols":"5","sm":"5"}},[_c('strong',[_vm._v("N. prodotti")])]),_c('v-col',{attrs:{"cols":"4","sm":"4"}})],1),_c('v-list',_vm._l((_vm.lists),function(list,index){return _c('v-list-item',{key:list.id,class:{ zebra: index % 2 == 0 },attrs:{"to":'/profile/lists/' + list.id}},[(!list.isEdit)?_c('v-row',{class:{ default: list.isDefault },attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"3","sm":"3"}},[_c('strong',{staticClass:"name"},[_vm._v(_vm._s(list.name))])]),_c('v-col',{attrs:{"cols":"5","sm":"5"}},[_c('span',[_vm._v(_vm._s(list.size)+" prodotti")])]),_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"cols":"4","sm":"4"}},[(!list.isDefault)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"secondary-icon-button mr-1",attrs:{"large":"","icon":""},on:{"click":function($event){$event.preventDefault();return _vm.setDefault(list.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-star-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(" Imposta come lista preferita")])]):_vm._e(),(list.isDefault)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"main-button mr-1",attrs:{"large":"","icon":""},on:{"click":function($event){$event.preventDefault();return _vm.setDefault(list.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"default-icon"},[_vm._v("mdi-star")])],1)]}}],null,true)},[_c('span',[_vm._v(" Imposta come lista preferita")])]):_vm._e(),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"main-button mr-1",attrs:{"large":"","icon":""},on:{"click":function($event){$event.preventDefault();return _vm.addAllToCart(list)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$cart")])],1)]}}],null,true)},[_c('span',[_vm._v(" Aggiungi tutto al carrello")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"main-button mr-1",attrs:{"large":"","icon":""},on:{"click":function($event){$event.preventDefault();return _vm.setEdit(list)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Rinomina lista")])]),(!list.isDefault)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"secondary-icon-button",attrs:{"large":"","icon":""},on:{"click":function($event){$event.preventDefault();return _vm.removeList(list.id, list.name)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Elimina lista")])]):_vm._e()],1)],1):_vm._e(),(list.isEdit)?_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('v-text-field',{attrs:{"hide-details":"auto"},on:{"click":function($event){$event.preventDefault();return (function () {}).apply(null, arguments)}},model:{value:(list.tempName),callback:function ($$v) {_vm.$set(list, "tempName", $$v)},expression:"list.tempName"}})],1),_c('v-col',{attrs:{"cols":"2","sm":"2"}}),_c('v-col',{attrs:{"cols":"4","sm":"4"}},[_c('v-btn',{attrs:{"large":"","icon":""},on:{"click":function($event){$event.preventDefault();return _vm.confirmEdit(list)}}},[_c('v-icon',[_vm._v("mdi-check")])],1),_c('v-btn',{attrs:{"large":"","icon":""},on:{"click":function($event){$event.preventDefault();return _vm.undoEdit(list)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1):_vm._e()],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }