<template>
  <div class="generic-list scroll-container">
    <!-- <v-container class="fill-height"> -->
    <CategoryTitle :category="category" />

    <v-row class="d-flex flex-column create-list-wrapper" no-gutters>
      <v-col sm="12" md="4">
        <h2>Crea una nuova lista</h2>

        <div class="create-input d-flex align-center">
          <v-text-field
            label="Inserisci il nome"
            hide-details="auto"
            v-model="listName"
          ></v-text-field>
          <v-btn
            elevation="2"
            medium
            class="primary"
            v-on:click="createList()"
            :disabled="listName.trim().length === 0"
            >CREA</v-btn
          >
        </div>
      </v-col>
    </v-row>

    <ListsTable
      :lists="lists"
      @setdefault="setDefaultList"
      @remove="removeList"
      @rename="renameList"
    ></ListsTable>
    <v-card
      light
      outlined
      v-if="lists && lists.length == 0"
      class="pa-2 text-center"
      style="width:100%;"
    >
      <v-card-title class="headline">Nessuna lista trovata</v-card-title>
    </v-card>
    <!-- </v-container> -->
    <v-spacer class="spacer-scroll"></v-spacer>
  </div>
</template>
<script>
// @ is an alias to /src
import CategoryTitle from "@/components/category/CategoryTitle";
import ListsTable from "@/components/lists/ListsTable";

import list from "~/mixins/list";
import categoryMixins from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";

export default {
  name: "Lists",
  mixins: [list, categoryMixins, deliveryReactive],
  data() {
    return {
      page: {}
    };
  },

  components: {
    CategoryTitle,
    ListsTable
  },
  created() {
    this.reload();
  }
};
</script>
